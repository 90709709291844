import axios from "axios";

const refreshToken = async () => {
  try {
    const getRefreshToken = await axios.post(`/api/refresh-token`);
    return getRefreshToken.data.token;
  } catch (error) {
    if (error.message.includes("Status: 401")) {
      console.debug("Refresh token is invalid");
    }
  }
};

export default refreshToken;
