import { Center, Spinner } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect } from "react";
import { atom, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import refreshToken from "./refresh-token";
import { useLogout } from "app/hooks/useLogout";

const parseJwt = (token: string): Record<string, any> | null => {
  try {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  } catch (e) {
    return null;
  }
};

export const sessionTokenState = atom({
  key: "SessionToken",
  default: "",
  effects: [
    ({ onSet, setSelf }) => {
      if (typeof window !== "undefined") {
        try {
          const savedValue = window.localStorage.getItem("token");
          if (savedValue != null) {
            setSelf(savedValue);
          }
        } catch (error) {
          console.log(error);
        }

        onSet((newValue, _, isReset) => {
          isReset
            ? localStorage.removeItem("key")
            : localStorage.setItem("token", newValue);
        });
      }
    }
  ]
});

const PUBLIC_ROUTES = ["auth", "terms", "privacy"];

const SessionProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const tokenLoadable = useRecoilValueLoadable(sessionTokenState);
  const token = tokenLoadable.getValue();
  const setSessionToken = useSetRecoilState(sessionTokenState);
  const logout = useLogout();

  const getRefreshToken = useCallback(async () => {
    try {
      const newToken = await refreshToken();
      setSessionToken(newToken);
    } catch (error) {
      if (error.message.includes("Status: 401")) {
        console.debug("Refresh token is invalid");
      } else {
        console.debug(error);
      }
      logout();
    }
  }, []);

  const handleRefresh = useCallback(() => {
    const payload = parseJwt(token);

    if (!payload) {
      console.debug("No token found");
      logout();
      return;
    }

    if (!payload.exp) {
      console.debug("Token has no expiry");
      return;
    }

    const expiresAt = new Date(0);
    expiresAt.setUTCSeconds(payload.exp - 60); // 1 minute before token expired

    if (new Date() > expiresAt) {
      console.debug("Token will expired in 1 minute, refreshing token");
      getRefreshToken();
      return;
    }

    console.debug("Token is still valid until", expiresAt);
  }, [token]);

  const onActive = () => {
    handleRefresh();
  };

  const onAction = () => {
    handleRefresh();
  };

  const idleTimer = useIdleTimer({
    onActive,
    onAction,
    timeout: 10000,
    debounce: 3000
  });

  useEffect(() => {
    if (!token && !PUBLIC_ROUTES.includes(router.asPath.split("/")[1])) {
      router.replace("/auth/login");
    }
  }, [router, token]);

  if (tokenLoadable.state === "loading" && !token) {
    return (
      <Center w="100vw" h="100vh">
        <Spinner
          speed="0.8s"
          size="lg"
          thickness="3px"
          color="blue.400"
          emptyColor="gray.200"
        />
      </Center>
    );
  }

  return <>{children}</>;
};

export default SessionProvider;
