import env from "./env";

let backendBasePath = "";
if (process.env.NODE_ENV === "development") {
  backendBasePath = "/api/backend";
} else if (env.public.NEXT_PUBLIC_QORE_DATA_ENDPOINT) {
  backendBasePath = env.public.NEXT_PUBLIC_QORE_DATA_ENDPOINT;
}

export default backendBasePath;
