import Script from "next/script";
import { ChakraProvider } from "@chakra-ui/react";
import qoreContext from "app/common/qoreContext";
import type { AppProps } from "next/app";
import Head from "next/head";
import theme from "../theme";
import { Global, css } from "@emotion/react";
import SessionProvider from "app/common/SessionProvider";
import { RecoilRoot } from "recoil";
import React, { ReactElement, ReactNode } from "react";
import { NextPage } from "next";

import "@fontsource/inter";
import "focus-visible/dist/focus-visible";

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      <Head>
        <title>Qore: Backend-as-A-Service platform</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="msapplication-TileColor" content="#037AA9" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Digitalize Your Business operation with Qore"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Script src="https://cdn.jsdelivr.net/npm/systemjs@6.10.2/dist/s.min.js" />
      <Global
        styles={css`
          .js-focus-visible :focus:not([data-focus-visible-added]) {
            outline: none;
            box-shadow: none;
          }

          body.has-modal iframe {
            pointer-events: none;
          }
        `}
      />
      <RecoilRoot>
        <ChakraProvider resetCSS theme={theme}>
          <SessionProvider>
            <qoreContext.context.Provider
              value={{ client: qoreContext.client }}
            >
              {getLayout(<Component {...pageProps} />)}
            </qoreContext.context.Provider>
          </SessionProvider>
        </ChakraProvider>
      </RecoilRoot>
    </>
  );
}

MyApp.getInitialProps = () => ({});

export default MyApp;
