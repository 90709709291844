import React from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { useSetRecoilState } from "recoil";
import {
  userExternalIdState,
  userIdState
} from "@modules/dashboard/dashboard-store";

export const useLogout = () => {
  const router = useRouter();
  const toast = useToast();
  const setUserId = useSetRecoilState(userIdState);
  const setUseExternalrId = useSetRecoilState(userExternalIdState);

  return React.useCallback(
    async (toRegister?: boolean) => {
      try {
        if (localStorage.getItem("token")) {
          await axios.post("/api/logout", {
            token: localStorage.getItem("token")
          });
          localStorage.removeItem("token");
        }
        Cookies.remove("BUILDER-TOKEN");
        setUserId("");
        setUseExternalrId("");
        const search = Object.entries(router.query)
          .map(([key, val]) => `${key}=${val}`)
          .join("&");
        const nextPath = toRegister ? "/auth/register" : "/auth/login";

        router.push(search.length === 0 ? nextPath : `${nextPath}/?${search}`);
      } catch (error) {
        toast({
          title: error.response.data.message,
          status: "error",
          duration: 2000
        });
      }
    },
    [router]
  );
};
