import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: "#171717"
      }
    }
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`
  },
  colors: {
    brand: {
      "50": "#E5F0FF",
      "100": "#B8D4FF",
      "200": "#8AB8FF",
      "300": "#5C9CFF",
      "400": "#2E81FF",
      "500": "#0065FF",
      "600": "#0051CC",
      "700": "#003D99",
      "800": "#002866",
      "900": "#001433"
    },
    blue: {
      "50": "#E6F0FF",
      "100": "#B8D4FF",
      "200": "#8AB8FF",
      "300": "#5C9CFF",
      "400": "#2E81FF",
      "500": "#0065FF",
      "600": "#0051CC",
      "700": "#003D99",
      "800": "#002866",
      "900": "#001433"
    },
    red: {
      "50": "#FCE9EA",
      "100": "#F6C1C3",
      "200": "#F0999D",
      "300": "#EA7176",
      "400": "#E4494F",
      "500": "#DE2129",
      "600": "#B11B21",
      "700": "#851419",
      "800": "#590D10",
      "900": "#2C0708"
    },
    orange: {
      "50": "#FEF1E6",
      "100": "#FED9B9",
      "200": "#FDC08C",
      "300": "#FCA85F",
      "400": "#FB8F32",
      "500": "#FA7705",
      "600": "#C85F04",
      "700": "#964703",
      "800": "#642F02",
      "900": "#321801"
    },
    yellow: {
      "50": "#FEFAE7",
      "100": "#FCF1BB",
      "200": "#F9E88F",
      "300": "#F7DF64",
      "400": "#F5D638",
      "500": "#F3CD0C",
      "600": "#C2A40A",
      "700": "#927B07",
      "800": "#615205",
      "900": "#312902"
    },
    green: {
      "50": "#ECFAEA",
      "100": "#CBF2C5",
      "200": "#A9EA9F",
      "300": "#87E179",
      "400": "#66D954",
      "500": "#44D12E",
      "600": "#37A725",
      "700": "#297D1C",
      "800": "#1B5313",
      "900": "#0E2A09"
    },
    purple: {
      "50": "#F1E5FF",
      "100": "#D7B8FF",
      "200": "#BD8AFF",
      "300": "#A35CFF",
      "400": "#892EFF",
      "500": "#6F00FF",
      "600": "#5900CC",
      "700": "#430099",
      "800": "#2C0066",
      "900": "#160033"
    }
  }
});

export default theme;
