// NB: please use this utility only on the server side
import getConfig from "next/config";

const getEnv = (variable: string, fallback?: string) => {
  const { serverRuntimeConfig } = getConfig();
  const val = serverRuntimeConfig[variable] || fallback;
  if (typeof val === "undefined") throw new Error(`${variable} is not set`);
  return val;
};

const getPublicEnv = (variable: string, fallback?: string) => {
  const { publicRuntimeConfig } = getConfig();
  const val = publicRuntimeConfig[variable] || fallback;
  if (typeof val === "undefined") throw new Error(`${variable} is not set`);
  return val;
};

export default {
  QORE_DATA_ENDPOINT: getEnv("QORE_DATA_ENDPOINT", ""),
  QORE_DATA_ADMIN_SECRET: getEnv("QORE_DATA_ADMIN_SECRET", ""),
  TOTP_SECRET: getEnv("TOTP_SECRET", ""),
  GENESIS_ENDPOINT: getEnv("GENESIS_ENDPOINT", ""),
  GENESIS_ADMIN_SECRET: getEnv("GENESIS_ADMIN_SECRET", ""),
  GENESIS_INGRESS: getEnv("GENESIS_INGRESS", ""),
  QORE_PIPELINE_ENDPOINT: getEnv("QORE_PIPELINE_ENDPOINT", ""),
  QORE_PIPELINE_ADMIN_SECRET: getEnv("QORE_PIPELINE_ADMIN_SECRET", ""),
  GENESIS_SERVICE_NAME: getEnv("GENESIS_SERVICE_NAME", "genesis-svc"),
  GENESIS_SERVICE_PORT: getEnv("GENESIS_SERVICE_PORT", "80"),
  K8S_NAMESPACE: getEnv("K8S_NAMESPACE", "feedloop"),
  STATIC_DEPLOY_DOMAIN: getEnv("STATIC_DEPLOY_DOMAIN", ""),
  QORE_PIPELINE_TENANCY_ENDPOINT: getEnv("QORE_PIPELINE_TENANCY_ENDPOINT", ""),
  QORE_PIPELINE_TENANCY_ADMIN_SECRET: getEnv("QORE_PIPELINE_TENANCY_ADMIN_SECRET", ""),
  QORE_PIPELINE_DEFAULT_SENTRY_DSN: getEnv("QORE_PIPELINE_DEFAULT_SENTRY_DSN", ""),
  /***
   * After add new variables please update sed on the docker file
   * qore-console/Dockerfile line 67 for public environment and
   * line 66 for server environment
   */
  public: {
    NEXT_PUBLIC_ADVERTISED_HOST: getPublicEnv(
      "NEXT_PUBLIC_ADVERTISED_HOST",
      ""
    ),
    NEXT_PUBLIC_ADVERTISED_DOMAIN: getPublicEnv(
      "NEXT_PUBLIC_ADVERTISED_DOMAIN",
      ""
    ),
    NEXT_PUBLIC_QORE_DATA_ENDPOINT: getPublicEnv(
      "NEXT_PUBLIC_QORE_DATA_ENDPOINT",
      ""
    ),
    NEXT_PUBLIC_QORE_APPS_ENDPOINT: getPublicEnv(
      "NEXT_PUBLIC_QORE_APPS_ENDPOINT",
      ""
    ),
    NEXT_PUBLIC_PIPELINE_TENANCY_HOST: getPublicEnv(
      "NEXT_PUBLIC_PIPELINE_TENANCY_HOST",
      ""
    ),
    NEXT_PUBLIC_PIPELINE_TENANCY_SECRET: getPublicEnv(
      "NEXT_PUBLIC_PIPELINE_TENANCY_SECRET",
      ""
    ),
    SENTRY_AUTH_TOKEN: getPublicEnv("SENTRY_AUTH_TOKEN", ""),
    NEXT_PUBLIC_MODULE_PROJECT_ID: getPublicEnv(
      "NEXT_PUBLIC_MODULE_PROJECT_ID",
      ""
    ),
    NEXT_PUBLIC_MODULE_URL: getPublicEnv("NEXT_PUBLIC_MODULE_URL", ""),
    NEXT_PUBLIC_TEMPLATE_PROJECT_ID: getPublicEnv(
      "NEXT_PUBLIC_TEMPLATE_PROJECT_ID",
      ""
    ),
    NEXT_PUBLIC_TEMPLATE_URL: getPublicEnv("NEXT_PUBLIC_TEMPLATE_URL", ""),
    NEXT_PUBLIC_TEMPLATE_ENTERPRISE_PROJECT_ID: getPublicEnv(
      "NEXT_PUBLIC_TEMPLATE_ENTERPRISE_PROJECT_ID",
      ""
    ),
    NEXT_PUBLIC_TEMPLATE_ENTERPRISE_URL: getPublicEnv(
      "NEXT_PUBLIC_TEMPLATE_ENTERPRISE_URL",
      ""
    ),
    NEXT_PUBLIC_FEATURE_MULTITENANCY: getPublicEnv(
      "NEXT_PUBLIC_FEATURE_MULTITENANCY",
      ""
    ),
    NEXT_PUBLIC_ENABLE_GENESIS_CREATE_PROJECT: getPublicEnv(
      "NEXT_PUBLIC_ENABLE_GENESIS_CREATE_PROJECT",
      ""
    )
  }
};
