import { Role } from "./../types/role";
import {
  Application,
  Organization,
  Project,
  Team,
  User,
  UserDetailOrganization,
  UserDetailTeam,
} from "@modules/types";
import qoreContext from "app/common/qoreContext";
import { atom, selector, selectorFamily } from "recoil";
import _ from "lodash";
import localStorageEffect from "@modules/recoil/effects/localStorageEffect";

export const userQuery = selectorFamily({
  key: "UserQuery",
  get: (userId: string) => async () => {
    if (!userId) return;
    const res = await qoreContext.client
      .table("users")
      .readRow(userId)
      .toPromise();

    return res.data as User;
  }
});

export const organizationQuery = selectorFamily({
  key: "OrganizationQuery",
  get: (orgId: string) => async () => {
    const res = await qoreContext.client
      .view("organization-detail")
      .readRows({
        params: { organizationId: orgId }
      })
      .toPromise();

    return res.data?.nodes[0] as Organization;
  }
});

export const teamUserListFilterQuery = selectorFamily({
  key: "teamUserListFilterQuery",
  get:
    (data: {
      userId: string | number;
      teamId: string | number;
      role: string | number;
    }) =>
    async () => {
      const res = await qoreContext.client
        .view("team-user-list-filter")
        .readRows({
          condition: {
            user: data.userId
          },
          params: { teamId: data.teamId, role: data.role }
        })
        .toPromise();

      return res.data?.nodes[0] as Organization;
    }
});

export const organizationListState = selector({
  key: "OrganizationList",
  get: async () => {
    const res = await qoreContext.client
      .view("organization-list")
      .readRows()
      .toPromise();

    return res.data?.nodes as Organization[];
  }
});

export const teamQuery = selectorFamily({
  key: "TeamQuery",
  get: (teamId: string) => async () => {
    const res = await qoreContext.client
      .view("team-detail")
      .readRows({ params: { teamId } })
      .toPromise();

    return res.data?.nodes[0] as Team;
  }
});

export const teamListQuery = selectorFamily({
  key: "TeamListQuery",
  get: (orgId: string) => async () => {
    const res = await qoreContext.client
      .view("team-list")
      .readRows({ params: { organizationId: orgId } })
      .toPromise();

    return res.data?.nodes as Team[];
  }
});

export const projectListQuery = selectorFamily({
  key: "ProjectListQuery",
  get: (params: { orgId: string; teamId: string }) => async () => {
    const newProjectList = await fetch(
      `/api/projects?orgId=${params.orgId}&teamId=${params.teamId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    const newProjectListData = await newProjectList.json();

    return newProjectListData as Project[];
  }
});

export const roleQuery = selectorFamily({
  key: "RoleQuery",
  get:
    ({ orgId }: { orgId: string }) =>
    async () => {
      const { data } = await qoreContext.client
        .view("organization-user-list")
        .readRows({
          params: { organizationId: orgId }
        })
        .toPromise();

      return data?.nodes[0] as Role;
    }
});

export const currentUserQuery = selectorFamily({
  key: "CurrentUserQuery",
  get: () => async () => {
    const newProjectList = await qoreContext.client.project.axios.get(
      `/v1/user`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return newProjectList.data;
  }
});

export const detailUserOrganizationQuery = selectorFamily({
  key: "DetailUserOrganizationQuery",
  get:
    (params: { userId: string | number; orgId: string | number }) =>
    async () => {
      if (!params.userId || !params.orgId) return;
      const { data } = await qoreContext.client
        .table(`organization_user`)
        .readRows({
          condition: {
            $and: [
              { "organization-user": params.userId },
              {
                [`organization-id`]: params.orgId
              }
            ]
          }
        })
        .toPromise();

      if (data?.nodes && data.nodes.length) {
        const [user] = data.nodes;
        return user as UserDetailOrganization;
      }
      return;
    }
});

export const detailUserTeamQuery = selectorFamily({
  key: "DetailUserTeamQuery",
  get:
    (params: { userId: string | number; teamId: string | number }) =>
      async () => {
        if (!params.userId || !params.teamId) return;
        const { data } = await qoreContext.client
          .table(`team_user`)
          .readRows({
            condition: {
              $and: [
                { "team-user": params.userId },
                {
                  [`team-id`]: params.teamId
                }
              ]
            }
          })
          .toPromise();

        if (data?.nodes && data.nodes.length) {
          const [user] = data.nodes;
          return user as UserDetailTeam;
        }
        return;
      }
});

export const userIdState = atom({
  key: "UserId",
  default: "",
  effects: [localStorageEffect("userId")]
});

export const userExternalIdState = atom({
  key: "UserExternalId",
  default: "",
  effects: [localStorageEffect("userExternalId")]
});

export const orgIdState = atom({
  key: "OrgId",
  default: "",
  effects: [localStorageEffect("orgId")]
});

export const teamIdState = atom({
  key: "TeamId",
  default: "",
  effects: [localStorageEffect("teamId")]
});

export const cardSortState = atom({
  key: "CardSortState",
  default: "oldest"
});

export const roleSortState = atom({
  key: "roleSortState",
  default: ""
});

export const paginationState = atom({
  key: "paginationState",
  default: 10
});

export const projectListState = atom({
  key: "ProjectListState",
  default: [] as Project[]
});

export const appListState = atom({
  key: "AppListState",
  default: [] as Application[]
});

export const cardHoverState = atom({
  key: "CardHoverState",
  default: ""
});

function sort(list: Project[] | Application[], type: string) {
  let result = [...list];
  switch (type) {
    case "latest":
      result = result.reverse();
      break;
    case "a-z":
      result = result.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      break;
    case "z-a":
      result = result.sort((a, b) =>
        a.name < b.name ? 1 : b.name < a.name ? -1 : 0
      );
      break;
  }
  return result;
}

export const sortedProjectListState = selector({
  key: "SortedProjectListState",
  get: ({ get }) => {
    const sortState = get(cardSortState);
    const list = get(projectListState);
    return sort(list, sortState);
  }
});

export const sortedAppListState = selector({
  key: "SortedAppListState",
  get: ({ get }) => {
    const sortState = get(cardSortState);
    const list = get(appListState);
    return sort(list, sortState);
  }
});

export const searchValueState = atom({
  key: "SearchValueState",
  default: ""
});

export const filteredProjectList = selector({
  key: "FilteredProjectList",
  get: ({ get }) => {
    const list = get(sortedProjectListState);
    const searchValue = get(searchValueState);

    if (searchValue) {
      return list.filter(
        project =>
          project.name.toLowerCase().includes(searchValue.trim()) ||
          project.name.includes(searchValue.trim())
      );
    }

    return list;
  }
});

export const filteredAppList = selector({
  key: "FilteredAppList",
  get: ({ get }) => {
    const list = get(sortedAppListState);
    const searchValue = get(searchValueState);

    if (searchValue) {
      return list.filter(
        app =>
          app.name.toLocaleLowerCase().includes(searchValue.trim()) ||
          app.name.includes(searchValue.trim())
      );
    }

    return list;
  }
});
