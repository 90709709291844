import { QoreClient } from "@feedloop/qore-client";
import createQoreContext from "@feedloop/qore-react";
import backendBasePath from "./backendBasePath";

const client = new QoreClient({
  endpoint: backendBasePath,
  getToken: () => {
    if (typeof window === "undefined") return undefined;
    return localStorage?.getItem("token") || undefined;
  },
  onError: (error: any) => {
    const response = error?.response;
    if (!response) {
      console.error(error);
    }

    if (
      response.data?.message === "administrator is not a role" &&
      typeof window !== "undefined"
    ) {
      localStorage?.clear();
      window.location.reload();
    }
  }
});

const qoreContext = createQoreContext(client);

export default qoreContext;
